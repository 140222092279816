<template>
<el-dialog
        title="我需要这类客户"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        custom-class="need-dialog"
        width="654px"
    >
<el-form
            v-loading="citysLoading || industryLoading"
            :model="needForm"
            :rules="needRrules"
            ref="needForm"
            label-width="104px"
            class="need-form"
        >
<div class="form-tips">将你需要的客户类别反馈给锐眼。锐眼将跟你的需求，每日为你推荐目标客户。开通vip会员，还可以获得企业的联系方式。</div>

<el-form-item label="地 区" prop="cityCode">
<el-popover
                    placement="bottom"
                    width="438"
                    trigger="click"
                    popper-class="cus-multiple-select-popper"
                    @show="handleCitysPopperShow">
                    
                    <CusCascader
                        ref="citysCascader"
                        cascaderTitle="地区"
                        :cascaderData="citysData"
                        @change="({ type, data }) => handleCascaderChange({ key: 'cityCode', type, data })"
                    />

<div slot="reference">
<div class="el-select cus-multiple-select">
<div class="el-input__inner">
<div v-if="needForm.cityCode.length" class="el-select__tags">
<span>
<template v-for="item in citysData">
<span v-if="item.checked" :key="'cityCode' + item.value" class="el-tag el-tag--info el-tag--small el-tag--light">
<span class="el-select__tags-text">{{ item.label }}</span>
<i class="el-tag__close el-icon-close" @click="handleCascaderRemove('cityCode', item)"></i>
</span>

<template v-for="citem in item.children">
<span  v-if="citem.checked" :key="'cityCode' + citem.value" class="el-tag el-tag--info el-tag--small el-tag--light">
<span class="el-select__tags-text">{{ citem.label }}</span>
<i class="el-tag__close el-icon-close" @click="handleCascaderRemove('cityCode', citem)"></i>
</span>
</template>
</template>
</span>
</div>
                                
                                <div v-else class="cus-multiple-select__placeholder">全部地区</div>
                                
                                <div class="cus-multiple-select__icon">
<i class="el-icon-arrow-down"></i>
</div>
</div>
</div>
</div>
</el-popover>
</el-form-item>

<el-form-item label="行 业" prop="industry">
<el-popover
                    placement="bottom"
                    width="438"
                    trigger="click"
                    popper-class="cus-multiple-select-popper"
                    @show="handleIndustryPopperShow">
                    
                    <CusCascader
                        ref="industryCascader"
                        cascaderTitle="行业"
                        :cascaderData="industryData"
                        @change="({ type, data }) => handleCascaderChange({ key: 'industry', type, data })"
                    />

<div slot="reference">
<div class="el-select cus-multiple-select">
<div class="el-input__inner">
<div v-if="needForm.industry.length" class="el-select__tags">
<span>
<template v-for="item in industryData">
<span v-if="item.checked" :key="'industry' + item.value" class="el-tag el-tag--info el-tag--small el-tag--light">
<span class="el-select__tags-text">{{ item.label }}</span>
<i class="el-tag__close el-icon-close" @click="handleCascaderRemove('industry', item)"></i>
</span>

<template v-for="citem in item.children">
<span  v-if="citem.checked" :key="'industry' + citem.value" class="el-tag el-tag--info el-tag--small el-tag--light">
<span class="el-select__tags-text">{{ citem.label }}</span>
<i class="el-tag__close el-icon-close" @click="handleCascaderRemove('industry', citem)"></i>
</span>
</template>
</template>
</span>
</div>
                                
                                <div v-else class="cus-multiple-select__placeholder">全部行业</div>
                                
                                <div class="cus-multiple-select__icon">
<i class="el-icon-arrow-down"></i>
</div>
</div>
</div>
</div>
</el-popover>
</el-form-item>

<el-form-item label="行业关键词" prop="keyWord">
<div class="el-select cus-multiple-select">
<div class="el-input__inner">
<div class="el-select__tags">
<span>
<span v-for="(item, index) in needForm.keyWord" :key="'keyWord' + index" class="el-tag el-tag--info el-tag--small el-tag--light">
<span class="el-select__tags-text">{{ item }}</span>
<i class="el-tag__close el-icon-close" @click="handleKeyWordRemove(index)"></i>
</span>
</span>

<div v-if="needForm.keyWord.length < 10">
<input v-model.trim="keyWordValue" type="text" autocomplete="off" class="el-select__input cus-keyword-input" placeholder="输入关键词" @blur="handleKeywordsBlur" @keyup.enter="handleKeywordsChange">

<el-button type="text" size="mini" @click="handleKeywordsChange">+继续添加</el-button>
</div>
</div>
</div>
</div>

<div class="form-item-tips">添加关键词，让推荐的目标客户更加精准（每个关键词不超过5个字）</div>
</el-form-item>

<el-form-item v-for="item in needData" :key="item.prop" :label="item.label" :prop="item.prop">
<el-select v-model="needForm[item.prop]" placeholder="请选择">
<el-option v-for="(citem, cidx) in item.options" :key="item.prop + cidx + citem.value" :label="citem.label" :value="citem.value"></el-option>
</el-select>
</el-form-item>

<div class="el-dialog__footer">
<el-button type="text" @click="dialogVisible = false">稍后设置</el-button>
<el-button v-loading="sending" type="primary" @click="handleToSubmit">提交需求</el-button>
</div>
</el-form>
</el-dialog>
</template>

<script>
import CusCascader from '/src/components/FormPage/cusCascader.vue';

import { ApiLoginUpdateUserKhzyConfigure, ApiLoginGetUserKhzyConfigure } from '/src/api/login';
import { getCitys, getCitysMap, getIndustry, getIndustryMap, getStatic } from '/src/utils/staticData';

export default {
    name: "CneedCompany",
    components: {
        CusCascader,
    },
    // props: {
    //     exportTotal: {
    //         type: Number,
    //         default: 0,
    //     },
    // },
    data() {
        // const checkMaxItem = (rule, value, callback) => {
        //     if (!value || !value.length) {
        //         return callback(new Error('不能为空'));
        //     } else if (value && value.length > 5) {
        //         return callback(new Error('最多选择5个选项'));
        //     }
            
        //     return callback();
        // };

        return {
            dialogVisible: false,
            citysLoading: false,
            industryLoading: false,
            cfgLoading: false,
            sending: false,

            staticData: getStatic(),

            citysChecked: [],
            citysData: [],
            citysMap: null,

            industryData: [],
            industryMap: null,
            keyWordValue: '',
            keyWordOptions: [],

            needData: [
                {
                    prop: 'estiblishTime',
                    label: '公司成立时间',
                    options: [],
                },
                {
                    prop: 'regCapital',
                    label: '公司注册资本',
                    options: [],
                },
                // {
                //     prop: 'companyType',
                //     label: '企业类型',
                //     options: [],
                // },
                {
                    prop: 'personnelScale',
                    label: '公司人员规模',
                    data: [],
                },
            ],
            needForm: {
                cityCode: [],
                industry: [],
                keyWord: [],
                estiblishTime: '',
                regCapital: '',
                // companyType: '',
                personnelScale: '',
            },
            needRrules: {
                cityCode: [
                    { required: false, type: 'array', max: 5, message: '最多选择5个选项' }
                ],
                industry: [
                    { required: false, type: 'array', max: 5, message: '最多选择5个选项' }
                ],
                keyWord: [
                    { required: false, type: 'array', max: 10, message: '最多输入10个关键词' }
                ],
            },

            keyWordValue: '',
        };
    },
    computed: {
        userInfo() {
            return this.$store.getters.userInfo || {};
        },
    },
    // created() {
    // },
    mounted() {
        // this.handleOpen();
    },
    methods: {
        handleOpen() {
            this.dialogVisible = true;

            this.handleGetUserKhzyConfigure(); 
            
            this.$nextTick(() => {
                this.$refs.needForm.clearValidate();
            });
        },

        // 静态数据初始化
        handleStaticDataInit() {
            const { staticData, needData } = this;

            needData.forEach((item) => {
                if (staticData[item.prop]) {
                    item.options = staticData[item.prop];
                }
            });
            
            this.handleGetCitys();
            this.handleGetIndustry();
        },

        // 获取省份城市
        handleGetCitys() {
            if (this.citysLoading) {
                return false;
            }

            this.citysLoading = true;

            getCitys((data) => {
                this.citysLoading = false;

                if (data) {
                    const { cityCode } = this.needForm;

                    this.citysData = data.map((item) => {
                        const tmpItem = {
                            value: item.code,
                            label: item.name,
                            checked: cityCode.indexOf(item.code) >= 0,
                            children: [],
                        };

                        if (item.citys && item.citys.length) {
                            tmpItem.children = item.citys.map((citem) => {
                                const tmpCitem = {
                                    value: citem.code,
                                    label: citem.name,
                                    checked: cityCode.indexOf(citem.code) >= 0,
                                    leaf: true,
                                };

                                return tmpCitem;
                            });
                        }

                        return tmpItem;
                    });
                }
            });
        },

        // 获取省份城市
        handleGetIndustry() {
            if (this.industryLoading) {
                return false;
            }

            this.industryLoading = true;

            getIndustry((data) => {
                this.industryLoading = false;

                if (data) {
                    const { industry } = this.needForm;

                    this.industryData = data.map((item) => {
                        const tmpItem = {
                            value: item.code,
                            label: item.name,
                            checked: industry.indexOf(item.code) >= 0,
                            children: [],
                        };

                        if (item.children && item.children.length) {
                            tmpItem.children = item.children.map((citem) => {
                                return {
                                    value: citem.code,
                                    label: citem.name,
                                    checked: industry.indexOf(citem.code) >= 0,
                                    leaf: true,
                                };
                            });
                        }

                        return tmpItem;
                    });
                }
            });
        },

        // 获取关注的客户需求信息
        handleGetUserKhzyConfigure() {
            const { needForm } = this;

            if (this.cfgLoading) {
                return false;
            }

            this.cfgLoading = true;

            ApiLoginGetUserKhzyConfigure().then((res) => {
                this.cfgLoading = false;

                const tmpData = res.data || {};
                // cityCode	城市code	string	
                // estiblishTime	成立时间范围	string	
                // industry	所属行业code	string	
                // keyWord	行业关键词	string	
                // personnelScale	人员规模	string	
                // provinceCode	省份code	string	
                // regCapital	注册资本
                // companyType	企业类型

                tmpData.cityCode = tmpData.cityCode ? tmpData.cityCode.split(',') : [];
                tmpData.industry = tmpData.industry ? tmpData.industry.split(',') : [];
                tmpData.keyWord = tmpData.keyWord ? tmpData.keyWord.split(',') : [];
                
                Object.keys(tmpData).forEach((key) => {
                    needForm[key] = tmpData[key];
                });

                this.handleStaticDataInit();
            }).catch(() => {
                this.cfgLoading = false;
            });
        },

        // 获取已选项的数组
        handleGetCheckedData(data) {
            let tmpList = [];

            data.forEach((item) => {
                if (item.checked) {
                    tmpList.push(item.value);
                }

                if (item.children && item.children.length) {
                    tmpList = tmpList.concat(this.handleGetCheckedData(item.children));
                }
            });

            return tmpList;
        },

        // 城市选项下拉框展示
        handleCitysPopperShow() {
            this.$refs.citysCascader.handleInit();
        },
        handleIndustryPopperShow() {
            this.$refs.industryCascader.handleInit();
        },

        // 城市，值改变
        handleCascaderChange({ key, type, data }) {
            switch(type) {
                case 'value':
                    this.needForm[key] = this.handleGetCheckedData(data);

                    break;                
                default:
                    break;
            }
        },

        // 移除城市行业选项
        handleCascaderRemove(key, item) {
            const { needForm } = this;

            item.checked = false;

            needForm[key] = needForm[key].filter(code => code !== item.value);
        },

        // 移除关键词
        handleKeyWordRemove(idx) {
            const { needForm } = this;

            needForm.keyWord.splice(idx, 1);
        },

        // 关键词输入框失去焦点
        handleKeywordsBlur(value) {
            if (value) {
                this.handleKeywordsChange();
            }
        },

        // 关键词输入框回车
        handleKeywordsChange() {
            const { keyWordValue, needForm } = this;
            const tmpData = needForm.keyWord || [];
            const tmpWordMaxLen = 5;

            tmpData.push(keyWordValue);

            // console.log(value);
            if (tmpData && tmpData.length) {
                let tmpValue = tmpData.filter((item) => {
                    // 移除超出长度的值
                    return item.length <= tmpWordMaxLen;
                });

                if (tmpValue.length !== tmpData.length) {
                    this.$message.error('每个关键词不超过'+ tmpWordMaxLen +'个字');
                }

                // 去重
                needForm.keyWord = Array.from(new Set(tmpValue));
            }

            this.keyWordValue = '';
        },

        handleToSubmit() {
            const { needForm } = this;

            if (this.sending) {
                return false;
            }

            // console.log(needForm);

            this.$refs.needForm.validate((valid) => {
                if (valid) {
                    this.sending = true;

                    const tmpQuery = {
                        cityCode: needForm.cityCode.join(','),
                        estiblishTime: needForm.estiblishTime,
                        industry: needForm.industry.join(','),
                        keyWord: needForm.keyWord.join(','),
                        personnelScale: needForm.personnelScale,
                        provinceCode: '',
                        regCapital: needForm.regCapital,
                        // companyType: needForm.companyType,
                    };

                    // "cityCode": "1301",
                    // "estiblishTime": "（1：一年内 2：1-3年 3：3-5年 4：5-10年 5：10-15年 6：15年以上）",
                    // "industry": "123",
                    // "keyWord": "123",
                    // "personnelScale": "1：50万以内 2：50-100万 3:100-500万 4：500-1000万:5：1000-5000万 6：5000万以上",
                    // "provinceCode": "13",
                    // "regCapital": "1：50万以内 2：50-100万 3:100-500万 4：500-1000万:5：1000-5000万 6：5000万以上"

                    ApiLoginUpdateUserKhzyConfigure(tmpQuery).then(() => {
                        this.sending = false;

                        this.dialogVisible = false;

                        this.$message.success('保存成功！');

                        this.$emit('success');
                    }).catch(() => {
                        this.sending = false;
                    });
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.need-form {
    padding: 16px 48px 6px;
}

.cus-keyword-input {
    width: 80px;
    height: 20px;
    padding: 0 5px;
    border: 1px dashed #E03A3E;
    margin-left: 6px;

    font-size: 12px;
}

.form-tips {
    margin-bottom: 30px;

    font-size: 14px;
    line-height: 20px;
}

.form-item-tips {
    padding-top: 3px;

    font-size: 12px;
    color: #535353;
    line-height: 1.1;
}

.el-form-item {
    &.is-error {
        .form-item-tips {
            display: none;
        }
    }
}

.el-dialog__footer {
    padding-top: 12px;
}
</style>
